var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.title,"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(true)?_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Cliente")])]),_c('validation-provider',{attrs:{"name":"client","vid":"client","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.clients,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un rol","disabled":_vm.disableAll},model:{value:(_vm.reserve.client),callback:function ($$v) {_vm.$set(_vm.reserve, "client", $$v)},expression:"reserve.client"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Método de Pago")])]),_c('validation-provider',{attrs:{"name":"payment_method","vid":"payment_method","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.payment_methods,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un metodo de pago","disabled":_vm.disableAll},model:{value:(_vm.reserve.payment_method),callback:function ($$v) {_vm.$set(_vm.reserve, "payment_method", $$v)},expression:"reserve.payment_method"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-success","disabled":_vm.disablePlusButton},on:{"click":_vm.addRoom}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:('CREATE'),expression:"'CREATE'"}]})],1)],1)]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},_vm._l((_vm.reserve.detail),function(detail,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Habitación")])]),_c('validation-provider',{attrs:{"name":"room","vid":"room","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.rooms,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un metodo de pago","disabled":_vm.disableAll},model:{value:(_vm.reserve.detail[index].room),callback:function ($$v) {_vm.$set(_vm.reserve.detail[index], "room", $$v)},expression:"reserve.detail[index].room"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Fecha ingreso")])]),_c('validation-provider',{attrs:{"name":"start_date","vid":"start_date","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","type":"date","disabled":_vm.disableAll},model:{value:(_vm.reserve.detail[index].start_date),callback:function ($$v) {_vm.$set(_vm.reserve.detail[index], "start_date", $$v)},expression:"reserve.detail[index].start_date"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Fecha salida")])]),_c('validation-provider',{attrs:{"name":"end_date","vid":"end_date","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","type":"date","disabled":_vm.disableAll},model:{value:(_vm.reserve.detail[index].end_date),callback:function ($$v) {_vm.$set(_vm.reserve.detail[index], "end_date", $$v)},expression:"reserve.detail[index].end_date"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"2"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Borrar'),expression:"'Borrar'",modifiers:{"bottom":true}}],attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteRoom(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon","size":"15"}})],1)],1)],1)}),1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}}),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"reserve-number"}},[_vm._v("Total")])]),_c('validation-provider',{attrs:{"name":"reserve-cost","vid":"reserve-cost","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"w-100",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_vm._v("S/.")])]},proxy:true}],null,true)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######.##'),expression:"'#######.##'"}],class:{ 'border-danger rounded': errors[0] },attrs:{"placeholder":"Total de la reserva","disabled":_vm.disableAll},model:{value:(_vm.reserve.total),callback:function ($$v) {_vm.$set(_vm.reserve, "total", $$v)},expression:"reserve.total"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"light","disabled":_vm.disableAll},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":_vm.registerReserve}},[_vm._v(" Guardar ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)]}}],null,false,3010495571)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }