<template>
  <div>
    <b-modal
      :title="title"
      v-model="showModal"
      modal-class="modal-primary"
      hide-footer
      @hidden="close"
      size="lg"
      loading
      no-close-on-backdrop
      no-close-on-esc
    >
      <validation-observer ref="checkForm" #default="{ invalid }" v-if="true">
          <b-row>
            <b-col cols="6" class="mt-1">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="user-document-type">Cliente</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="client"
                  vid="client"
                  rules="chat-compose-required"
                >
                  <v-select
                    small
                    :class="{ 'border-danger rounded': errors[0] }"
                    v-model="reserve.client"
                    :options="clients"
                    :reduce="(val) => val.id"
                    label="name"
                    class="w-100"
                    hint="Seleccione un rol"
                    :disabled="disableAll"
                  ></v-select>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="mt-1">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="user-document-type">Método de Pago</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="payment_method"
                  vid="payment_method"
                  rules="chat-compose-required"
                >
                  <v-select
                    small
                    :class="{ 'border-danger rounded': errors[0] }"
                    v-model="reserve.payment_method"
                    :options="payment_methods"
                    :reduce="(val) => val.id"
                    label="name"
                    class="w-100"
                    hint="Seleccione un metodo de pago"
                    :disabled="disableAll"
                  ></v-select>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-1">
              <div class="d-flex justify-content-end">
                <b-button
                  variant="outline-success"
                  @click="addRoom"
                  :disabled="disablePlusButton"
                >
                  <feather-icon icon="PlusIcon" size="15" class="mr-1" />
                  <span v-t="'CREATE'"></span>
                </b-button>
              </div>
            </b-col>

            <b-col cols="12" class="mt-1">
              <b-row v-for="(detail, index) in reserve.detail" :key="index">
                <b-col cols="4">
                  <b-form-group>
                    <div class="d-flex justify-content-between text-white">
                      <label for="user-document-type">Habitación</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="room"
                      vid="room"
                      rules="chat-compose-required"
                    >
                      <v-select
                        small
                        :class="{ 'border-danger rounded': errors[0] }"
                        v-model="reserve.detail[index].room"
                        :options="rooms"
                        :reduce="(val) => val.id"
                        label="name"
                        class="w-100"
                        hint="Seleccione un metodo de pago"
                        :disabled="disableAll"
                      ></v-select>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group>
                    <div class="d-flex justify-content-between text-white">
                      <label for="user-document-type">Fecha ingreso</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="start_date"
                      vid="start_date"
                      rules="chat-compose-required"
                    >
                      <b-form-input
                        small
                        :class="{ 'border-danger rounded': errors[0] }"
                        v-model="reserve.detail[index].start_date"
                        type="date"
                        :disabled="disableAll"
                      ></b-form-input>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group>
                    <div class="d-flex justify-content-between text-white">
                      <label for="user-document-type">Fecha salida</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="end_date"
                      vid="end_date"
                      rules="chat-compose-required"
                    >
                      <b-form-input
                        small
                        :class="{ 'border-danger rounded': errors[0] }"
                        v-model="reserve.detail[index].end_date"
                        type="date"
                        :disabled="disableAll"
                      ></b-form-input>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="mt-2">
                  <b-button
                    variant="outline-danger"
                    @click="deleteRoom(index)"
                    v-b-tooltip.bottom="'Borrar'"
                  >
                    <feather-icon icon="MinusIcon" size="15" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="6" class="mt-1"> </b-col>

            <b-col cols="6" class="mt-1">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="reserve-number">Total</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="reserve-cost"
                  vid="reserve-cost"
                  rules="chat-compose-required"
                >
                  <b-input-group class="w-100">
                    <template v-slot:prepend>
                      <b-input-group-text>S/.</b-input-group-text>
                    </template>
                    <b-form-input
                      :class="{ 'border-danger rounded': errors[0] }"
                      v-model="reserve.total"
                      placeholder="Total de la reserva"
                      v-mask="'#######.##'"
                      :disabled="disableAll"
                    ></b-form-input>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  class="mx-1"
                  variant="light"
                  :disabled="disableAll"
                  @click="close"
                >
                  Cancelar
                </b-button>
                <b-button
                  v-if="!loading"
                  type="submit"
                  @click="registerReserve"
                  variant="primary"
                  :disabled="invalid || disableAll"
                >
                  Guardar
                </b-button>
                <b-button v-else variant="primary" disabled>
                  <b-spinner small />
                  Cargando...
                </b-button>
              </div>
            </b-col>
          </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>
  
<script>
import ReserveService from "@/views/main/views/reserve/service/reserve.service.js"
import ClientService from "@/views/main/views/client/services/client.service.js"
import RoomService from "@/views/main/views/room/services/room.service.js"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "client",
    },
  },
  data() {
    return {
      disablePlusButton: false,
      title: "Crear reserva",
      showModal: true,
      loading: false,
      countries: [],
      document_types: [],
      disableAll: false,
      disable: {
        type: true,
        first_name: true,
        last_name: true,
      },
      reserve: {
        client: null,
        detail: [
          {
            room: 1,
            start_date: null,
            end_date: null,
          },
        ],
      },
      clients: [
      ],

      payment_methods: [] ,
      rooms: [],
    };
  },

  watch: {
    "reserve.detail": {
      handler: function (val) {
        if (val.length >= 5) {
          this.disablePlusButton = true;
        } else {
          this.disablePlusButton = false;
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/dataUser",
    }),
  },

  methods: {
    async getClients(){
      const {data} = await ClientService.listSelect();
      this.clients = data;
    },
    async getRooms(){
      const {data} = await RoomService.listSelect();
      this.rooms = data.map((element) => {return {"id":element.id, "name":element.number}});
    },

    async getMethods(){
      const {data} = await RoomService.methodSelect();
      this.payment_methods = data
    },
    addRoom() {
      this.reserve.detail.push({
        room: 1,
        start_date: null,
        end_date: null,
      });
    },
    deleteRoom(index) {
      if (this.reserve.detail.length > 1) {
        this.reserve.detail.splice(index, 1);
      }
    },
    close(action = false) {
      this.$emit("close", action);
    },

    async registerReserve() {
      const response = await this.$refs.checkForm.validate();
      if (response) {
        await this.register();
      }
    },
    async register() {
      this.reserve.personal = this.currentUser.id
      await this.addPreloader();
      let response = {};
      this.loading = true;
      try {
        response = await ReserveService.register(this.reserve);
        if (response.data.code == 200) {
          this.$emit("refreshTable");
          this.close();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Reserva Registrada",
              icon: "ThumbsUpIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      } catch (error) {
        this.loading = false;
        this.disableAll = true;

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error.response?.data?.error,
          },
        });
        // }
      } finally {
        this.loading = false;
        this.disableAll = false;
        await this.removePreloader();
      }
    },
  },

  created(){
    this.getClients();
    this.getRooms();
    this.getMethods();
  }
};
</script>
  
<style></style>