export default [
  {
    // [0]
    key: "client",
    label: "Cliente",
    visible: true,
  },
  {
    // [1]
    key: "method_payment",
    label: "Método de pago",
    visible: true,
  },
  {
    // [1]
    key: "total",
    label: "Total",
    visible: true,
  },
  {
    // [2]
    key: "personal",
    label: "Personal",
    visible: true,
  },
  {
    // [3]
    key: "reserve_date",
    label: "Fecha de reserva",
    visible: true,
  },
  {
    // [3]
    key: "observation",
    label: "Observación",
    visible: true,
  },
  {
    key: "actions",
    label: "Acciones",
    visible: true,
  },
];
